import type { NextPage, GetStaticPaths, GetStaticProps } from 'next';

import Page from '@components/Page';
import PageSplash from '@components/PageSplash';

import {
  Copy,
  quiz,
  QuizData,
  copy as copyAPI,
  externalLinks as externalLinkAPI,
  collections as collectionsAPI,
  ExternalLinks,
  Locale,
  ResultCollections
} from '@utils/api';
import { getStaticPathsHelper } from '@utils/page';
import { useQuizStore } from 'src/hooks/useQuiz';
import { useMount } from 'react-use';

export type HomeProps = {
  copy: Copy;
  locale: Locale;
  externalLinks: ExternalLinks;
  collections: ResultCollections;
  quizData: QuizData;
};

export const getStaticPaths: GetStaticPaths = getStaticPathsHelper;

export const getStaticProps: GetStaticProps<HomeProps> = async context => {
  const locale = context.params.locale as Locale;
  const quizData = await quiz(locale);
  const copy = await copyAPI(locale, 'home');
  const externalLinks = await externalLinkAPI();
  const collections = await collectionsAPI(locale);
  return {
    props: {
      copy,
      locale,
      externalLinks,
      collections,
      quizData
    }
  };
};

const Home: NextPage<HomeProps> = ({
  copy,
  quizData,
  locale,
  externalLinks,
  collections
}) => {
  const [setupIfRequired, reset] = useQuizStore(state => [
    state.setupIfRequired,
    state.reset
  ]);
  setupIfRequired(locale, quizData);

  useMount(() => {
    // always reset, since we can no longer have a history...
    reset();
  });

  return (
    <Page
      copy={copy}
      locale={locale}
      externalLinks={externalLinks}
      collections={collections}
    >
      <PageSplash />
    </Page>
  );
};

export default Home;
