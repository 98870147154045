import type { TextProps, HeadingProps, FlexProps } from '@chakra-ui/react';
import { rem } from '@styles/textStyles';

export const innerContainer: FlexProps = {
  flexDirection: 'column',
  alignItems: { base: 'flex-start', md: 'center' }
};
export const container: FlexProps = {
  py: {
    base: rem(22),
    md: rem(57),
    '2xl': rem(72)
  },
  px: {
    base: rem(22),
    md: rem(80)
  }
};
export const heading: HeadingProps = {
  textStyle: 'h1',
  lineHeight: { base: rem(48), md: rem(51), '2xl': rem(67) },
  fontSize: {
    base: rem(45),
    md: rem(48),
    '2xl': rem(64)
  },
  mb: { base: rem(12), md: rem(8) },
  textTransform: 'uppercase',
  fontWeight: '800',
  maxW: rem(640),
  textAlign: {
    base: 'initial',
    md: 'center'
  },
  color: 'color.black'
};
export const pargraph: TextProps = {
  fontFamily: 'copy',
  lineHeight: { base: rem(20), md: rem(29), '2xl': rem(39) },
  fontSize: { base: rem(16), md: rem(24), '2xl': rem(32) },
  fontWeight: '500',
  maxWidth: rem(640),
  mb: { base: rem(16), md: rem(30) },
  color: 'color.black',
  letterSpacing: '-0.02em',
  textAlign: {
    base: 'initial',
    md: 'center'
  }
};
