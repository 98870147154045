import { useCallback, useContext, useMemo } from 'react';
import { Box, Heading, Text, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import shallow from 'zustand/shallow';

import type {
  NikeActivityFinderAnswers,
  NikeActivityFinderQuestions
} from 'src/queries/directus';

import Layout from '@components/Layout';
import { PageContext } from '@components/Page';
import Answers, { AnswersVariant } from '@components/Answers';
import { link } from '@components/Link';
import { convertAnswer, useQuizStore } from 'src/hooks/useQuiz';
import { pargraph, heading, container, innerContainer } from './styles';
import { rem } from '@styles/textStyles';
import { sendEvent } from '@utils/analytics';

const PageSplash = () => {
  const router = useRouter();
  const { copy, locale, region } = useContext(PageContext);

  const quizStore = useQuizStore(
    state => ({
      quizData: state.quizData,
      selectedAnswer: state.selectedAnswer,
      setSelectedAnswer: state.setSelectedAnswer,
      submitSelectedAnswer: state.submitSelectedAnswer,
      currentQuestion: state.currentQuestion,
      responses: state.responses,
      tags: state.getTags(),
      filteredResults: state.getFilteredResults(),
      getAnswerIds: state.getAnswerIds,
      getFilteredAnswers: state.getFilteredAnswers,
      setResponses: state.setResponses
    }),
    shallow
  );

  const question = useMemo<NikeActivityFinderQuestions>(() => {
    const { questions } = quizStore.quizData;
    return questions.find(q => q.id === '0') || questions[0];
  }, [quizStore]);

  const selectAnswer = useCallback(
    (answer: NikeActivityFinderAnswers) => {
      quizStore.setSelectedAnswer(answer);

      const hasNextQuestion = !!quizStore.setResponses(
        [
          {
            questionId: question.id,
            answer: convertAnswer(answer)
          }
        ],
        true
      );

      // Track in GA
      sendEvent('answer', {
        answer_name: answer.name,
        question_id: question.id
      });

      router.push(
        link(
          {
            pathname: hasNextQuestion ? '/quiz' : '/result',
            query: {
              answers: answer.id
            }
          },
          locale,
          region
        )
      );
    },
    [quizStore, question, locale, region, router]
  );
  const getHeadingStyle = (language: string) => {
    return {
      maxWidth: language.includes('de') ? rem(850) : rem(640)
    };
  };

  return (
    <Layout hasFooter={false}>
      <Box {...container}>
        <Flex {...innerContainer}>
          <Heading {...heading} style={getHeadingStyle(locale)}>
            {copy('home.heading')}
          </Heading>
          <Text {...pargraph}>{question.translations[0].text}</Text>
        </Flex>
        <Answers
          variant={AnswersVariant.SMALL}
          answers={question.answers}
          selectAnswer={selectAnswer}
        />
      </Box>
    </Layout>
  );
};

export default PageSplash;
